.component-enlistment {
    $header-height: 48px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 51;

    backdrop-filter: blur(5px);

    display: flex;
    align-items: center;
    justify-content: center;

    & > section {
        width: 100%;
        max-width: 1000px;
        border-radius: 0.75rem;

        display: flex;
        flex-direction: column;

        & > div:first-of-type {
            flex-grow: 1;
        }

        .enlistment__step0, .enlistment__step1, .enlistment__step2, .enlistment__step3, .enlistment__step4 {
            max-width: 700px;

            .enlisted__step0__username {
                background-color: rgb(var(--color-primary-600) / var(--tw-bg-opacity));
            }

            a {
                color: rgb(var(--color-primary-600) / var(--tw-bg-opacity));
            }

            ol {
                list-style: decimal;
            }

        }

        .enlistment__bar {
            background-color: rgb(var(--color-primary-600) / var(--tw-bg-opacity));
            border-bottom-left-radius: 0.75rem;
            border-bottom-right-radius: 0.75rem;

            .circles {
                & > div {
                    $size: 14px;
                    width: $size;
                    height: $size;
                    border-radius: 100%;
                }
            }
        }


    }

    @media screen and (max-width: 600px) {
        top: $header-height;
        height: calc(100% - $header-height);
        & > section {
            height: 100%;
            border-radius: 0;

            .enlistment__step0, .enlistment__step1, .enlistment__step2, .enlistment__step3, .enlistment__step4 {
                .flex {
                    flex-wrap: wrap;
                    & > div {
                        min-width: 100%;
                    }
                }
            }
        }
    }
}