.directory {
  &__filter-form {
    display: flex;
    gap: 30px;
    background: var(--foreground-color);

    &__column {
      padding-bottom: 10px;
    }

    .radio-button {
      display: block;
    }
  }

  &__list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    width: 100%;
    transition: opacity 100ms ease-in;
    box-sizing: border-box;

    &.loading {
      opacity: 0.7;
    }

    @media screen and (max-width: 630px) {
      grid-template-columns: minmax(0, 100%);
    }
  }

  &__card {
    @apply rounded-lg;
    box-sizing: border-box;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;

    .display-name {
      text-align: left;

      strong {
        @apply text-black dark:text-white;
        display: block;
        font-size: 15px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span:not(.verified-icon) {
        @apply text-gray-500 dark:text-gray-400;
        display: block;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .verified-icon div,
      .verified-icon svg {
        @apply inline;
      }
    }

    &__info {
      z-index: 1;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__action-button {
      z-index: 1;
      position: absolute;
      top: 78px;
      right: 12px;
    }

    &__img {
      @apply bg-primary-200 dark:bg-slate-600;
      height: 125px;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
      }
    }

    &__bar {
      @apply bg-primary-200 dark:bg-slate-600;
      display: flex;
      align-items: center;
      padding: 10px;

      &__name {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        text-decoration: none;
        overflow: hidden;
      }

      .account__avatar {
        flex: 0 0 auto;
        width: 48px;
        min-width: 48px;
        height: 48px;
        padding-top: 2px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          margin: 0;
          border-radius: 4px;
          background: var(--brand-color--faint);
          object-fit: cover;
        }
      }
    }

    &__extra {
      background: var(--foreground-color);
      display: flex;
      align-items: center;
      justify-content: center;

      .accounts-table__count {
        padding: 15px 0;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        width: 33.33%;
        flex: 0 0 auto;

        small {
          display: block;
          color: var(--primary-text-color--faint);
          font-weight: 400;
          font-size: 14px;
        }
      }

      .account__header__content {
        @apply border-b border-solid border-gray-200 dark:border-slate-600;
        box-sizing: border-box;
        padding: 15px 10px;
        width: 100%;
        min-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.empty {
          border-color: transparent;
        }

        p {
          display: none;

          &:first-child {
            display: inline;
          }
        }

        br {
          display: none;
        }
      }
    }
  }
}
